<template>
  <!-- <base-section id="deal-flow-heading" class="secondary"> -->
  <div>
    <heading-separator :title="title" :div="div"></heading-separator>
  </div>
  <!-- </base-section> -->
</template>

<script>
import HeadingSeparator from "./HeadingSeparator.vue";

export default {
  name: "DealFlowHeading",
  components: { HeadingSeparator },
  data() {
    return {
      title: "MavenX",
      div: "<div> <v-img src='@/assets/mavenx.png'></v-img> <br><p class='white--text'> MavenX is a powerful financial technology platform designed to help alternate asset managers streamline their entire deal lifecycle, by ensuring that the deals move from stage to stage faster while creating a single source of truth for all the deal related data, using custom workflows and approval matrices to optimize their processes. </p> <p class='white--text'> By leveraging MavenX, asset managers can enhance their deal sourcing capabilities, ensure faster due diligence, and improve their investor relations through personalized reporting and analytics, and ultimately drive growth and profitability in the highly competitive financial services industry.</p></div>",
    };
  },
};
</script>
